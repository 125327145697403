import axios from "axios";

export const SignIn = async(code: string | null) => {

    // const { code } = JSON.parse(req.body);
    const options = {
      method: "GET",
      url: `https://slack.com/api/oauth.v2.access?client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_SLACK_CLIENT_SECRET}&code=${code}&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}`,
      // url: `https://slack.com/api/oauth.v2.access?client_id=${process.env.NEXT_PUBLIC_SLACK_CLIENT_ID}&client_secret=${process.env.NEXT_PUBLIC_SLACK_CLIENT_SECRET}&code=${code}&redirect_uri=https://5481-2402-d000-8114-236d-e427-c8d5-805b-4901.in.ngrok.io/auth`,
    };
    try {
      let response = await axios(options);
      return {
        message: "success",
        data: response?.data
      }
    } catch (err) {
        return {
            message: "error",
            error: err
          }
    }

}