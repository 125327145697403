import React from "react";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import image404 from "../../images/page404.jpg"
import { useMediaQuery } from 'react-responsive'
export default function Page404() {


    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })

    return (
        <Card style={{ height: '100vh' }}>
            <div className="flex justify-content-center" >
                <img alt="Card" src={image404} width={isDesktopOrLaptop ? '30%' : '60%'} style={{ zIndex: 15, }} />
                {/* <p style={{ fontSize: 50, fontWeight: 800, margin: '2vh', }}>Error 404 - Not Found</p> */}
            </div>
            <div className="flex justify-content-center" >
                {/* <img alt="Card" src={image404} width={'30%'} style={{ zIndex: 15, }} /> */}
                <p style={{ fontSize: 50, fontWeight: 900, margin: '2vh', }}>Oops! </p>
            </div>
            <div className="flex justify-content-center" >
                {/* <h1  style={{fontWeight:600, fontSize:40}}>404 - PAGE NOT FOUND</h1> */}


            </div>

            <div className="flex justify-content-center" >
                <h3 style={{ color: 'black' }}>  The page you are looking for might have been removed, had its name changed or is temporarily unavailable </h3>
            </div>

            <div className=" my-6 flex justify-content-center" >
                <Button className="px-5" label="GO TO HOMEPAGE" aria-label="Edit" onClick={() => navigate("/templates")} />
            </div>
        </Card>

    );
}