
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import userExceed from "../../images/user_exceed.jpg"
import React from 'react';
export default function ExceedUserCount() {


    return (
        <Card style={{ height: '100vh' }}>
            <div className="flex justify-content-center" >
                {/* <img alt="Card" src={image404} width={'30%'} style={{ zIndex: 15, }} /> */}
                <p style={{ fontSize: 160, fontWeight: 800, margin: '2vh', color: '#005CE8' }}>Forbidden! </p>
            </div>
            <div className="flex justify-content-center" >
                {/* <h1  style={{fontWeight:600, fontSize:40}}>403 - ACCESS DENIED</h1> */}


            </div>

            <div className="flex justify-content-center" >
                <h3 style={{ color: 'black' }}>  User count exceed... Please contact your organization and upgrade your plan. </h3>
            </div>

            <div className=" flex justify-content-center" >
                <img alt="Card" src={userExceed} width={'30%'} style={{ zIndex: 15, }} />
            </div>
        </Card>

    );
}