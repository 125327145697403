import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { useParams } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import WarningLogo from '../../images/warning.png';
import SuccessLogo from '../../images/success.png';
import Padlock from '../../images/padlock.png'; 
import { Calendar } from 'primereact/calendar';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Avatar } from "primereact/avatar";
import { Link } from 'react-router-dom';
import { getPreset } from "../../api/Presets";
import { validateFlowsbyOrg, addFlow } from "../../api/Flows";
import WeeksArray from "../../components/WeeksArray";
import { getDayValue } from "../../helpers/GetDayValue";
import { sendIntroMessage } from "../../api/Messages";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle, faLock } from '@fortawesome/free-solid-svg-icons'
import EmojiConvertor from "emoji-js";
import { useMediaQuery } from 'react-responsive'
// import { SearchCustomer, ListSubscriptions } from '../../api/Stripe';
import { getOrganization } from "../../api/Organizations";
import { flow } from "../../types/types";

const moment = require("moment");
const timezones = [
    { name: 'Asia/Colombo' },
    { name: 'Europe/Stockholm' },
    { name: 'Asia/Singapore' }
];

const schedulesList = [
    { name: 'Weekly' },
    { name: '2 weeks period' },
    { name: '3 weeks period' },
    { name: '4 weeks period' },
    { name: 'Custom' },
];

function NewTemplate() {
    const toast = useRef(null);
    const { preset } = useParams();
    const [token, setToken] = useState("");
    const [channelId, setChannelId] = useState("");
    const [teamId, setTeamId] = useState("");
    const [userId, setuserId] = useState("");
    const [userName, setuserName] = useState("");
    const [visible, setVisible] = useState(false);
    const [visibleSuccess, setVisibleSuccess] = useState(false);
    const [showNewQuestion, setShowNewQuestion] = useState(false);
    const [date, setDate] = useState([]);
    const [selectMembers, setSelectMembers] = useState([]);
    const [title, setTitle] = useState('');
    const [intro, setIntro] = useState('');
    const [outro, setOutro] = useState('');
    const [convertedIntro, setConvertedIntro] = useState('');
    const [convertedoutro, setConvertedoutro] = useState('');
    const [time, setTime] = useState(null);
    const [schedule, setSchedule] = useState({ name: 'Weekly' });
    const [members, setMembers] = useState([]);
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [points, setPoints] = useState(1);
    const [newQuestion, setNewQuestion] = useState('');
    const [error, setError] = useState(false);
    const [errorCount, setErrorCount] = useState(false);
    const [errorUsers, setErrorUsers] = useState(true);
    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState(null);
    const [daysObj, setDaysObj] = useState({});
    const navigate = useNavigate();
    const emoji = new EmojiConvertor();
    emoji.replace_mode = 'unified';
    emoji.allow_native = true;
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })
    useEffect(() => {
        getTemplates(preset);
        console.log('i fire once');
        validatePlan();

    }, []);

    const getTemplates = async (preset) => {
        let authObj = JSON.parse(localStorage.getItem("auth")) || {};
        setToken(authObj?.token);
        setChannelId(authObj?.channel?.id);
        setTeamId(authObj?.team?.id);
        setuserId(authObj?.channel.slackUserId);
        const flow = await getPreset(preset) as flow;
        setTemplate(flow);
        setQuestions(flow?.questions);
        // setSchedule( { name: 'Weekly' })
        let userArr = [];
        let chanArr = [];
        const part = JSON.parse(window.localStorage.getItem("participantList"));
        setuserName(part[authObj?.channel.slackUserId].real_name);
        const result = Object.keys(part).map((key) => part[key]);
        result.map((res) => {
            userArr.push({ name: res.real_name, email: res.profile ? res.profile.email : "-", id: res.id })
        })
        setSelectMembers(userArr)
        const chan = JSON.parse(window.localStorage.getItem("channelList"));
        const result2 = Object.keys(chan).map((key) => chan[key]);
        result2.map((res) => {
            chanArr.push({ name: res.name, id: res.id })
        })
        setChannels(chanArr)
    };

    const validatePlan = async () => {
        let authObj = JSON.parse(localStorage.getItem("auth")) || {}; 
        const org = await getOrganization(authObj.team.id);
        const count = await validateFlowsbyOrg(authObj.team.id);
        if(count < 1){
            setErrorCount(false);
            setErrorUsers(false);
        }else if(org.subscriptionStatus == "active"){
            setErrorCount(false);
            setErrorUsers(false);
        }else{
            setErrorCount(true);
            setErrorUsers(true);
        }
        // const customerdata = await SearchCustomer(authObj.team.name);
        // const customer = customerdata?.data.id;
        // console.log(customer);
        // const subscriptions = await ListSubscriptions(customer);
        // console.log(subscriptions);
        // if (!(customer && subscriptions && subscriptions.plan && subscriptions.plan.product == "prod_QrzLnAcOFAHwYv")) {
            // if (customer && subscriptions) {
            // setErrorCount(false);
            // setErrorUsers(false);
            // if (count < 1) {
            //     // toast.current.show({ severity: 'warning', summary: 'Limit Reached', detail: 'Please upgrade your plan to add more workflows.' });
            //     // setErrorCount("Limit Reached. Please upgrade your plan to add more workflows.");
            //     setErrorCount(false);
            //     return 0;
            // }
        // }

    }

    const handleScheduleChange = (value) => {
        setSchedule(value);
        if (value.name == 'Weekly') {
            setPoints(1);
        } else if (value.name == '2 weeks period') {
            setPoints(2)
        } else if (value.name == '3 weeks period') {
            setPoints(3)
        } else if (value.name == '4 weeks period') {
            setPoints(4)
        }
    }

    const handleOnChange = (value, key) => {

        let obj = daysObj;
        if (!obj?.hasOwnProperty(key)) {
            let newArr = [];
            newArr[newArr.length] = value;
            obj[key] = newArr;
        } else {
            let existingArr = obj[key];
            if (!existingArr.includes(value)) {
                existingArr[existingArr.length] = value;
            } else {
                const index = existingArr.indexOf(value);
                if (index > -1) {
                    existingArr.splice(index, 1);
                }
            }
            obj[key] = existingArr;
        }
        setDaysObj(obj);
    };

    const onUserRemove = (index) => {
        let memb = [...members];
        if (index > -1) { // only splice array when item is found
            memb.splice(index, 1); // 2nd parameter means remove one item only
        }
        setMembers(memb);

    }

    const onQuestionEdit = (value, index) => {
        let ques = [...questions];
        // ques[index] = value;
        if (index > -1) {
            ques[index] = value;
        }
        setQuestions(ques);

    }

    const onQuestionRemove = (index) => {
        let ques = [...questions];
        if (index > -1) { // only splice array when item is found
            ques.splice(index, 1); // 2nd parameter means remove one item only
        }
        setQuestions(ques);

    }

    const convertEmojis = (place, value) => {
        const convert = emoji.replace_colons(value)
        if (place == "intro") {
            setIntro(value);
            setConvertedIntro(convert);
        } else if (place == "outro") {
            setOutro(value);
            setConvertedoutro(convert);
        }

    }


    const handleAddQuestion = () => {
        let questionArr = [...questions];
        questionArr.push(newQuestion);
        setQuestions(questionArr);
        setNewQuestion('');
    }

    const weekArr = (data) => {
        const mainArr = [];
        for (let index = 0; index < Object.keys(data).length; index++) {
            const elementKey = Object.keys(data)[index];
            const elementValue : any = Object.values(data)[index];

            let mainObj = {};
            let innerArr = [];
            for (let index = 0; index < elementValue.length; index++) {
                const element = elementValue[index];
                let innerObj = {
                    day: getDayValue(element),
                    createdAt: "",
                    untilAt: "",
                };
                innerArr[innerArr.length] = innerObj;
            }
            mainObj = {
                days: innerArr,
            };
            mainArr[mainArr.length] = mainObj;
        }

        return mainArr;
    };

    const submitClicked = async () => {

        setLoading(true);
        if (title.length == 0 || time == null || intro.length == 0 || outro.length == 0 || selectedTimezone == null || selectedChannel == null || schedule == null) {
            setError(true);
        } else {
            let authObj = JSON.parse(localStorage.getItem("auth")) || {};
            const count = await validateFlowsbyOrg(authObj.team.id);

            if (count >= 1) {
                setErrorCount(false);
                // const customerdata = await SearchCustomer(authObj.team.name);
                // const customer = customerdata?.data.id;
                // console.log(customer);
                // const subscriptions = await ListSubscriptions(customer);
                // console.log(subscriptions);
                
                const org = await getOrganization(authObj.team.id);
                if (org.subscriptionStatus != "active"){
                // if (!(customer && subscriptions && subscriptions.plan && subscriptions.plan.product == "prod_QrzLnAcOFAHwYv")) {
                    toast.current.show({ severity: 'warning', summary: 'Limit Reached', detail: 'Please upgrade your plan to add more workflows.' });
                    setErrorCount(true);
                    return 0;
                }
            }


            if (schedule && schedule.name == 'Custom') {

                let dateArr = date.map((day) => {
                    return moment(day).format('YYYY-MM-DD')
                });
                let participants = members.map((memb) => {
                    return memb.id
                });

                let obj = {
                    // id: params,
                    title: title,
                    days: {},
                    weeks: [],
                    customDates: dateArr,
                    schedule: schedule && schedule.name,
                    intro: intro,
                    outro: outro,
                    questions: questions,
                    key: template?.key || null,
                    time: moment(time).format("HH:mm"),
                    activeStatus: "Active",
                    timezone: selectedTimezone && selectedTimezone.name,
                    teamId: teamId,
                    channelId: channelId,
                    broadcastChannels: selectedChannel && [selectedChannel.id],
                    participants: participants,
                    token: token,
                    createdBy: userId
                };
                let flowId = await addFlow(obj);
                if (flowId) {
                    setVisibleSuccess(true);
                    participants.map(async (user) => {
                        let messageObj = {
                            addedBy: userId,
                            user: user,
                            title: title,
                            time: moment(time).format("HH:mm a"),
                            selectedTimezone: selectedTimezone && selectedTimezone.name,
                            selectedChannel: selectedChannel && selectedChannel.id

                        }
                        await sendIntroMessage(messageObj);
                    })
                    // navigate("/templates");
                }
            } else {
                let weeks = weekArr(daysObj);
                let participants = members.map((memb) => {
                    return memb.id
                });

                let obj = {
                    // id: params,
                    title: title,
                    schedule: schedule && schedule.name,
                    days: daysObj,
                    customDates: [],
                    intro: intro,
                    outro: outro,
                    questions: questions,
                    key: template?.key,
                    time: moment(time).format("HH:mm"),
                    activeStatus: "Active",
                    timezone: selectedTimezone && selectedTimezone.name,
                    teamId: teamId,
                    channelId: channelId,
                    broadcastChannels: selectedChannel && [selectedChannel.id],
                    participants: participants,
                    token: token,
                    weeks: weeks,
                    createdBy: userId
                };
                let flowId = await addFlow(obj);
                if (flowId) {
                    setVisibleSuccess(true);
                    participants.map(async (user) => {
                        let messageObj = {
                            addedBy: userId,
                            user: user,
                            title: title,
                            time: moment(time).format("hh:mm a"),
                            selectedTimezone: selectedTimezone && selectedTimezone.name,
                            selectedChannel: selectedChannel && selectedChannel.id

                        }
                        await sendIntroMessage(messageObj);
                    })
                    // navigate("/templates");
                }
            }


        }

        setLoading(false);




    }

    return (
        <div>
             <Dialog id="limitModal" visible={errorCount} style={{ width: '50vw' }} onHide={() => null}>
                <div className="flex text-center justify-content-center flex-wrap">
                    <img alt="Card" src={Padlock} width={'150px'} /></div>

                <div className="text-center justify-content-center font-bold border-round">
                    <h2 style={{ color: '#000766' }}>Limit Reached!</h2>
                    <p style={{ color: '#000766' }}>Please upgrade your plan to add more workflows.</p>
                </div>
                <div className="flex justify-content-evenly flex-wrap m-2">

                <Button className="px-5" label="Go Back" color="#005CE8" aria-label="Edit" onClick={() => navigate("/templates")} />
                </div>
            </Dialog>


            <Toast ref={toast}></Toast>
            <div className="p-3" style={{ background: '#ffffff' }}>

                <div style={{ padding: 0 }} className="grid flex align-items-center justify-content-center  ">
                    <div className="px-5 col-11">
                        <h2 style={{ margin: 0 }}>{title ? title : "New standup"}</h2></div>
                    <div className="col-1">
                        <Link to={'/templates'} style={{ textDecoration: 'none', }}>
                            <h2 style={{ margin: 0 }}>
                                <Button icon="pi pi-times" rounded outlined severity="secondary" aria-label="Edit" onClick={() => setVisible(true)} />

                            </h2>
                        </Link>

                        <Dialog visible={visible} style={{ width: '40vw' }} onHide={() => setVisible(false)}>
                            <div className="flex text-center justify-content-center flex-wrap">
                                <img alt="Card" src={WarningLogo} /></div>

                            <div className="text-center justify-content-center font-bold border-round">
                                <h2 style={{ color: '#000766' }}>You are about to delete this standup</h2>
                                <p>Are you sure you want to delete this? This action can not be undone!</p>
                            </div>
                            <div className="flex justify-content-evenly flex-wrap m-2">

                                <Button label="No, go back" severity="danger" text aria-label="Edit" onClick={() => setVisible(false)} />
                                <Button label="Delete" color="#005CE8" aria-label="Edit" onClick={() => setVisible(true)} />
                            </div>
                        </Dialog>
                        <Dialog visible={visibleSuccess} style={{ width: '40vw' }} onHide={() => setVisibleSuccess(false)}>
                            <div className="flex text-center justify-content-center flex-wrap">
                                <img alt="Card" src={SuccessLogo} /></div>

                            <div className="text-center justify-content-center font-bold border-round">
                                <h2 style={{ color: '#000766' }}>All Set!</h2>
                                <p style={{ color: '#000766' }}>Your workflow has been created.</p>
                            </div>
                            <div className="flex justify-content-evenly flex-wrap m-2">

                                <Button className="px-5" label="Done" color="#005CE8" aria-label="Edit" onClick={() => navigate("/workflows")} />
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>
            <Card className="m-5">


                <div className="flex flex-wrap">

                    <div className="mx-5" style={{ width: '45rem', }}>

                        <p>Title</p>
                        <InputText value={title} invalid={error && title.length == 0} onChange={(e) => setTitle(e.target.value)} className="w-full" />
                        {error && title.length == 0 ? (
                            <small id="error">
                                Field can not be empty
                            </small>
                        ) : (<small />)}



                        <div className="grid">
                            <div className="col">
                                <p>Time</p>
                                <Calendar
                                    // showIcon={true} iconPos="left" icon="pi pi-clock" 
                                    value={time} invalid={error && time == null} onChange={(e) => setTime(e.value)} timeOnly className="w-full" />
                                {error && time == null ? (
                                    <small id="error">
                                        Field can not be empty
                                    </small>
                                ) : (<small />)}
                            </div>

                            <div className="col">

                                <p>Timezone</p>
                                <Dropdown value={selectedTimezone} invalid={error && selectedTimezone == null} onChange={(e) => setSelectedTimezone(e.value)} options={timezones} optionLabel="name"
                                    className="w-full" />
                                {error && selectedTimezone == null ? (
                                    <small id="error">
                                        Field can not be empty
                                    </small>
                                ) : (<small />)}
                            </div>
                        </div>


                        <p>Channel</p>
                        <Dropdown value={selectedChannel} invalid={error && selectedChannel == null} onChange={(e) => setSelectedChannel(e.value)} options={channels} optionLabel="name"
                            className="w-full" />
                        {error && selectedChannel == null ? (
                            <small id="error">
                                Field can not be empty
                            </small>
                        ) : (<small />)}

                        <p>Intro Message</p>
                        <InputTextarea value={intro} invalid={error && intro.length == 0} placeholder="Write something..." onChange={(e) => convertEmojis("intro", e.target.value)} rows={3} className="w-full" />
                        {error && intro.length == 0 ? (
                            <small id="error">
                                Field can not be empty
                            </small>
                        ) : (<small />)}
                        {/* <InputText value={title} onChange={(e) => setTitle(e.target.value)} className="w-full" /> */}
                        {convertedIntro && <small style={{ color: "black" }}>{"Preview: " + convertedIntro}</small>}

                    </div>

                    <div className="mx-5" style={{ width: '45rem', }}>
                        <h4>Schedule</h4>

                        <p>When</p>
                        <Dropdown value={schedule} invalid={error && schedule == null} onChange={(e) => handleScheduleChange(e.value)} options={schedulesList} optionLabel="name"
                            className="w-full" />
                        {error && schedule == null ? (
                            <small id="error">
                                Field can not be empty
                            </small>
                        ) : (<small />)}

                        {schedule.name == 'Custom' ? (
                            <Calendar value={date} onChange={(e) => setDate(e.value)} inline selectionMode="multiple" style={{ width: '100%' }} showButtonBar={false} />

                        ) : (
                            <WeeksArray points={points} dateArrData={daysObj} disableBtns={false} handleOnChange={handleOnChange} />
                        )}
                    </div>

                    {/* <div className="mx-5" style={{ width: '35rem', }}>



                    </div> */}


                </div>

                <div className="mx-5" >
                    <h4>Questions</h4>
                </div>

                <div className="flex flex-wrap ">

                    {questions.map((question, index) => (
                        <div key={index} className="mx-5 my-3 " style={{ width: '45rem' }}>
                            <div key={index} className="grid flex align-items-center justify-content-center ">
                                <div key={index} className="col-11">
                                    <InputText key={index} value={question} onChange={(e) => onQuestionEdit(e.target.value, index)} className="w-full" />
                                </div>
                                <div className="col-1">
                                    {/* <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> */}
                                    {/* <Button icon="pi pi-times" rounded severity="danger" aria-label="Cancel" size="small" onClick={() => onQuestionRemove(index)} />*/}
                                    <FontAwesomeIcon color="#ef4444" size="2x" icon={faXmarkCircle} onClick={() => onQuestionRemove(index)} />
                                </div>
                            </div>

                        </div>
                    ))}

                    <div className="mx-5 my-3" style={{ width: '45rem' }}>
                        {!showNewQuestion ? (<Button label="Add question" severity="success" aria-label="Add question" rounded size="small" onClick={() => setShowNewQuestion(true)} />
                        ) : (<div />)}
                        {showNewQuestion ? (
                            <div className="grid">
                                <div className="col-11">
                                    <InputText value={newQuestion} placeholder={"Create another question"} onChange={(e) => setNewQuestion(e.target.value)} className="w-full" />
                                </div>
                                <div className="col-1">
                                    <Button label="Add" disabled={newQuestion === ''} aria-label="Add" size="small" onClick={handleAddQuestion} />
                                </div>
                            </div>
                        ) : (<div />)}

                    </div>


                </div>
                {isDesktopOrLaptop ? (
                    <div className="mx-5" >
                        <div style={{ width: '40%' }}>
                            <p>Outro Message</p>
                            <InputTextarea value={outro} invalid={error && outro.length == 0} placeholder="Write something..." onChange={(e) => convertEmojis("outro", e.target.value)} rows={3} className="w-full" />
                            {error && outro.length == 0 ? (
                                <small id="error">
                                    Field can not be empty
                                </small>
                            ) : (<small />)}
                            {convertedoutro && <small style={{ color: "black" }}>{"Preview: " + convertedoutro}</small>}
                        </div>
                    </div>
                ) : (
                    <div className="mx-5" >
                        <p>Outro Message</p>
                        <InputTextarea value={outro} invalid={error && outro.length == 0} placeholder="Write something..." onChange={(e) => convertEmojis("outro", e.target.value)} rows={3} className="w-full" />
                        {error && outro.length == 0 ? (
                            <small id="error">
                                Field can not be empty
                            </small>
                        ) : (<small />)}
                        {convertedoutro && <small style={{ color: "black" }}>{"Preview: " + convertedoutro}</small>}
                    </div>
                )}



                <div className="flex flex-wrap ">
                    <div style={{ width: '45rem' }} className="mx-5" >
                        <h4>Add members</h4>
                        {/* <div className="my-3" style={{ width: '45rem' }}>
                            <div className="grid">
                                <div className="col-11">
                                    <InputText value={newEmail} placeholder={"Email address"} onChange={(e) => setNewEmail(e.target.value)} className="w-full" />
                                </div>
                                <div className="col-1">
                                    <Button label="Send" severity="primary" aria-label="Cancel" size="small" disabled={newEmail === ''} onClick={() => setVisibleSuccess(true)} />
                                </div>
                            </div>
                            
                        </div> */}

                        <MultiSelect value={members} multiple={true} filter onChange={(e) => setMembers(e.value)} options={selectMembers} optionLabel="name"
                            className="w-full" selectionLimit={errorUsers ? 3 : null} />

                        {errorUsers &&
                            <small>
                                <FontAwesomeIcon color="#ef4444" icon={faLock} />
                                {" Only Premium users can add more than 3 members"}
                            </small>}


                    </div>
                    <div style={{ width: '45rem' }} className="mx-5" >
                    </div>
                </div>
                <div className="flex flex-wrap ">
                    {members && members.map((member, index) => (
                        <div className="mx-5 my-3 px-3 py-2" style={{ width: '25rem', border: '1px solid #E5E7E8', padding: 5 }} key={member}>
                            <div className="grid flex align-items-center justify-content-center ">
                                <div className="col-2">
                                    <Avatar label={member.name[0]} style={{ backgroundColor: '#FFBF66', color: '#ffffff', marginTop: 5 }} size="large" shape="circle" />
                                </div>
                                <div className="col-8">
                                    <h4 style={{ margin: 2 }}>{member.name}</h4>
                                    <p style={{ margin: 2, color: "#7B878C" }}>{member.email}</p>
                                </div>
                                <div className="col-2">
                                    <FontAwesomeIcon color="#ef4444" size="2x" icon={faXmarkCircle} onClick={() => onUserRemove(index)} />
                                    {/* <Button style={{ marginTop: 5 }} icon="pi pi-times" rounded severity="danger" aria-label="Cancel" size="small" onClick={() => onUserRemove(index)} /> */}
                                </div>
                            </div>

                        </div>
                    ))}


                </div>
                <div className="mx-5 p-5 flex align-content-end justify-content-end flex-nowrap gap-4" style={{ width: '100%', }}>

                    <div className="p-0">
                        <Button label="Go back" outlined rounded severity="secondary" aria-label="Cancel" size="small" onClick={() => navigate("/templates")} />
                    </div>
                    <div className="p-0">
                        <Button label="Save" disabled={errorCount ? true : loading ? true : false} rounded severity="info" aria-label="Cancel" size="small" onClick={() => submitClicked()} />
                    </div>
                </div>

            </Card>
        </div>
    );
}

export default NewTemplate;