import {
    collection,
    getDocs,
    query,
    where,
    orderBy
  } from "firebase/firestore";
  import { db, } from "../firebaseConfig";
  // import { TasksContext } from '../components/common/tasksContext';
  const moment = require("moment");
  const dbInstance = collection(db, "logs");

export const getLogs = async () => {
    try {
      let flows = await getDocs(dbInstance).then((data) => {
        return data;
      });
  
      return flows;
    } catch (error) {
      console.error("Error retreving document: ", error);
    }
  };

  export const logsPresentById = async (teamId: any, timestamp: { startDate: string; endDate: string; key: string; } | null) => {
    let q = null;
    if(timestamp){
      let startDate = moment(timestamp.startDate).format("YYYY-MM-DD 00:00");
      let endDate = moment(timestamp.endDate).format("YYYY-MM-DD 23:59");
      q =  query(collection(db, "logs"),where("teamId", "==", String(teamId)),where("timestamp", ">=", startDate),where("timestamp", "<", endDate),orderBy('timestamp', 'desc'),);
    }else{
      q =  query(collection(db, "logs"),where("teamId", "==", String(teamId)),orderBy('timestamp', 'desc'),);
    }
    let dataArr: any[] = [];
    const querySnapshot = await getDocs(q);
   
    let flows = querySnapshot.forEach((doc : any) => {
      // doc.data() is never undefined for query doc snapshots
      dataArr.push({
        ...doc.data(),
        id: doc.id
      })
    });
    console.log(dataArr)
    return dataArr ;
    
    
  };