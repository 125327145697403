
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
// import { ProductService } from './service/ProductService';

export default function InvoicesTable(props) {
    // const [products, setProducts] = useState([]);

    // useEffect(() => {
    //     ProductService.getProductsMini().then(data => setProducts(data));
    // }, []);
    const {invoices} = props;
    return (
        <div className="card">
            {invoices && invoices.length > 0 ? <DataTable value={invoices} tableStyle={{ minWidth: '50rem' }}>
                <Column field="number" header="Code"></Column>
                <Column field="createdAt" header="Date"></Column>
                <Column field="total" header="Amount"></Column>
                <Column field="status" header="Status"></Column>
                <Column header="Download" body={(<Button severity="success" icon="pi pi-download" size='small' rounded />)}></Column>
            </DataTable> : 'No data available'}
            


        </div>
    );
}
