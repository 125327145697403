
import { Card } from 'primereact/card';
import image403 from "../../images/page403.png"
import { useMediaQuery } from 'react-responsive'
import React from 'react';
export default function Page403() {

    
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })

    return (
        <Card style={{height: '100vh'}}>
                  <div className="flex justify-content-center" >
          <img alt="Card" src={image403} width={isDesktopOrLaptop ? '40%' : '70%'} style={{ zIndex: 15, }} />
          </div>
            <div className="flex justify-content-center" >
                {/* <h1  style={{fontWeight:600, fontSize:40}}>403 - ACCESS DENIED</h1> */}
          <p style={{ fontSize: 50, fontWeight:800, margin: '2vh',  }}>Forbidden! </p>
               

            </div>

            <div className="flex justify-content-center" >
                <h3 style={{ color: 'black' }}>  You are not authorized to access this page. Please contact the administrator. </h3>
            </div>

        {/* <div class="my-6 flex justify-content-center" >
        <Button className="px-5" label="GO TO HOMEPAGE" aria-label="Edit" onClick={() => navigate("/workflows")} />
        </div> */}
        </Card>

    );
}