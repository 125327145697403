import React from "react";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import Expired from "../../images/expired.png"
import { useMediaQuery } from 'react-responsive'
export default function TrialExpired() {


    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })

    return (
        <Card style={{ height: '100vh' }}>
            <div className="flex justify-content-center align-content-center" >
                <img alt="Card" src={Expired} width={isDesktopOrLaptop ? '30%' : '50%'} style={{ zIndex: 15, }} />
                <div className="flex justify-content-center align-content-center" >
                    <h1 style={{ fontSize: isDesktopOrLaptop ? 160 : 50, fontWeight: 800, margin: '2vh', color: '#005CE8' }}>Oops! </h1>
                </div>
            </div>
            <div className="flex justify-content-center" >
                <h1 style={{ fontWeight: 600, fontSize: isDesktopOrLaptop ? 40 : 18 }}>Looks like your free trial has ended</h1>

            </div>

            <div className="flex justify-content-center" >
                <h3 style={{ color: 'black' }}>  Please activate a premium plan and continue using our service... </h3>
            </div>

            <div className=" my-6 flex justify-content-center" >
                <Button className="px-5" label="CHOOSE PLAN" aria-label="Edit" onClick={() => navigate("/billing/new")} />
            </div>
        </Card>

    );
}