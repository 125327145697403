import { db } from "../firebaseConfig";
import { collection, addDoc, getDocs, doc, getDoc } from "firebase/firestore";

const dbInstance = collection(db, "templates");

export const getPresets = () => {
  let presets = getDocs(dbInstance).then((data) => {
    return data;
  });

  return presets;
};

export const getPreset = async (id: string) => {
    const docRef = doc(db, "templates", id);
    let preset = await getDoc(docRef);
    if (preset?.exists()) {
      return preset.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  
    return preset;
  };
  