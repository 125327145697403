import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { Calendar } from "primereact/calendar";
import { Avatar } from "primereact/avatar";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
const moment = require('moment')


function Filters(props) {

    // const [membersList, setMembersList] = useState([Members[0], Members[1]]);
    const [allChecked, setAllChecked] = useState(true);
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [dates, setDates] = useState([new Date(moment().startOf('week').format("YYYY-MM-DD") + " " + "00:00"), new Date(moment().endOf('week').format("YYYY-MM-DD") + " " + "23:59")]);


    useEffect(() => {
        setSelectedParticipants(props.flow.participants);
    }, [])

    const handleAllChecked = (check) => {
        setAllChecked(check);
        let array = [];
        let userarray = [];

        props.flow.participants.map((part) => {
            array[part] = check;
            if (check) {
                userarray.push(part);
            }
        })

        setSelectedParticipants(userarray);
        let obj = {
            startDate: moment(dates[0]).format("YYYY-MM-DD") + " " + "00:00",
            endDate: moment(dates[1]).format("YYYY-MM-DD") + " " + "23:59",
            key: 'selection',
        }
        props.handleUserChanged(obj, userarray);
    }

    const handleUserChecked = (id, check) => {
        let array = [...selectedParticipants];
        if (check) {
            array.push(id);
            setSelectedParticipants(array);
        } else {
            let index = array.indexOf(id)
            if (index !== -1) {
                array.splice(index, 1);
                setSelectedParticipants(array);
            }
        }

        // let checkArr = [];
        // array.map((arr)=>{
        //     checkArr[arr] = check;
        // })

        let obj = {
            startDate: moment(dates[0]).format("YYYY-MM-DD") + " " + "00:00",
            endDate: moment(dates[1]).format("YYYY-MM-DD") + " " + "23:59",
            key: 'selection',
        }
        props.handleUserChanged(obj, array);
    }

    const handleDateChanged = (date) => {
        setDates(date);
        let obj = {
            startDate: moment(date[0]).format("YYYY-MM-DD") + " " + "00:00",
            endDate: moment(date[1]).format("YYYY-MM-DD") + " " + "23:59",
            key: 'selection',
        }
        props.handleTimelineData(10, obj, selectedParticipants);
    }

    return (
        <Card title={"Date Range"}>
            <Calendar value={dates} onChange={(e) => handleDateChanged(e.value)} selectionMode="range" style={{ width: '95%' }} readOnlyInput hideOnRangeSelection />
            <div>
                <div className="grid">
                    <div className="col-9">
                        <h3>Participants {"(" + props.flow.participants.length + ")"}</h3></div>
                    <div className="col-1">
                        <h3>{"All "} </h3> </div>
                    <div className="col-2">
                        <h3>
                            <Checkbox
                                // style={{ marginTop: 18,}}
                                onChange={e => handleAllChecked(e.checked)}
                                checked={allChecked}></Checkbox></h3>
                    </div>
                </div>
                {props.flow.participants.map((member) => {
                    return (
                        <div className="grid">
                            <div className="flex wrap col-2 align-content-center justify-content-center">
                                <Avatar label={props?.participantObj.length && props?.participantObj[member]?.profile ? props?.participantObj[member]?.profile?.real_name[0] : props?.participantObj[member]?.real_name[0]}
                                    style={{ backgroundColor: props.participantObj && props.participantObj[member].color, color: '#ffffff', marginTop: 10 }} shape="circle" />
                            </div>
                            <div className="flex-col col-8 align-content-center  ">
                                <h4>{props?.participantObj.length && props?.participantObj[member]?.profile ? props?.participantObj[member]?.profile?.real_name : props?.participantObj[member]?.real_name}</h4>
                            </div>
                            <div className="flex-col col-2 align-content-center  ">

                                <Checkbox
                                    style={{}}
                                    onChange={e => handleUserChecked(member, e.checked)}
                                    checked={selectedParticipants.includes(member) ? true : false}>
                                    {/* fruits.includes("Mango"); */}

                                </Checkbox>

                            </div>
                        </div>
                    )
                })}
                {/* <Button style={{ padding: 1 }} text severity="secondary" aria-label="Bookmark" onClick={handleShowAllToggle}>

                    {
                        showAll === false ? (<h5 style={{ margin: 5, fontWeight: 200, color: '#959FA3' }}>{"Show all "}
                            <span style={{ width: 30, }} className="pi pi-angle-down"></span> </h5>) :
                            (<h6 style={{ margin: 5, fontWeight: 200, color: '#959FA3' }}>{"Show less "}
                                <span style={{ width: 30, }} className="pi pi-angle-up"></span> </h6>)
                    }

                </Button> */}
            </div>
        </Card>
    );
}

export default Filters;