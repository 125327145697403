import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Checkbox } from 'primereact/checkbox';
import { Button } from "primereact/button";

export default function PaymentMethodModal(props) {
    
    const { visible, setVisible, defaultCard, customerCards, addNewCard, Mastercard, Amex, Visa, changeDefaultCard, updatePlanFromModal, isDesktopOrLaptop } = props;

    const addCard = () => {
        addNewCard();
        setVisible(false);
    }

    return (
        <>
            <Dialog visible={visible} style={{ width: isDesktopOrLaptop ? '50vw' : '90vw' }} onHide={() => setVisible(false)}>
                <h3>Select Payment Method</h3>
                {customerCards.length > 0 && (
                    customerCards.map((card, index) => (
                        <div className='flex justify-content-center m-1' >
                            {defaultCard && card.card.brand === defaultCard.card.brand && card.card === defaultCard.card ? (
                                <div className='m-2 p-2 grid' style={{ backgroundColor: "#E9EFFF", border: "1px solid", borderColor: "#1B59F9", borderRadius: 15, width: '100%' }}>
                                    <div className=" col-2">
                                        <img alt="Card" src={card.card.brand === "visa" ? Visa : card.card.brand === "mastercard" ? Mastercard : card.card.brand === "amex" ? Amex : null} />


                                    </div>
                                    <div className="col-9">
                                        <div className='px-2'>
                                            <b>{card.card.brand + " ending in " + card.card.last4}</b>
                                            <p>{"Expiry " + card.card.exp_month + ' / ' + card.card.exp_year}</p>
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <Checkbox onClick={() => changeDefaultCard(card.id, index)} checked={true}></Checkbox>
                                    </div>


                                </div>
                            ) : (
                                <div className=' m-2 p-2 grid' style={{ border: "1px solid", borderColor: "#E4E7EC", borderRadius: 15, width: '100%' }}>
                                    <div className="col-2">
                                        <img alt="Card" src={card.card.brand === "visa" ? Visa : card.card.brand === "mastercard" ? Mastercard : card.card.brand === "amex" ? Amex : null} />
                                    </div>
                                    <div className="col-9 ">
                                        <div className='px-2'>
                                            <b>{card.card.brand + " ending in " + card.card.last4}</b>
                                            <p>{"Expiry " + card.card.exp_month + ' / ' + card.card.exp_year}</p>
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <Checkbox onClick={() => changeDefaultCard(card.id, index)} checked={false}></Checkbox>
                                    </div>


                                </div>
                            )}

                        </div>
                    ))
                )}
                <div className='flex justify-content-end m-2 '  >
                    <Button className="w-full mx-1" severity='secondary' onClick={() => addCard()}>Add new card</Button>
                    <Button className="w-full mx-1"  onClick={() => updatePlanFromModal()}>Proceed</Button>
                </div>
            </Dialog>
        </>
    );
}