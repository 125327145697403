
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import payImage from "../../images/pay_success.png"
import { useMediaQuery } from 'react-responsive'
export default function BillingSuccess() {

    
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })

    return (
        <Card style={{height: '100vh'}}>
                  <div className="flex justify-content-center" >
          <img alt="Card" src={payImage} width={isDesktopOrLaptop ? '40%' : '70%'} style={{ zIndex: 15, }} />
          </div>
            <div className="flex justify-content-center" >
                {/* <h1  style={{fontWeight:600, fontSize:40}}>403 - ACCESS DENIED</h1> */}
          <p style={{ fontSize: 50, fontWeight:800, margin: '2vh',  }}>Success! </p>
               

            </div>

            <div className="flex justify-content-center" >
                <h3 style={{ color: 'black' }}> Payment received.. </h3>
            </div>

        <div className="my-6 flex justify-content-center" >
        <Button className="px-5" label="GO TO HOME" aria-label="Edit" onClick={() => navigate("/workflows")} />
        </div>
        </Card>

    );
}