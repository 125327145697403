import { db } from "../firebaseConfig";
import { collection, addDoc, getDocs, doc, getDoc, setDoc, query, where, orderBy, updateDoc, deleteDoc } from "firebase/firestore";

const dbInstance = collection(db, "organizations");
const moment = require("moment");

export const addOrganization = async (data: { channel: any; team: any; token?: any; userType?: any; activeStatus?: boolean; profile?: { fname: string; lname: string; dob: string; email: string; occupation: string; gender: string; slackName: string; }; }, formData ?: any) => {
  try {
    // const docRef = await addDoc(collection(db, "users"), data);
    const currentDateUsingTZ = moment();
    const trialend = moment(currentDateUsingTZ, "DD-MM-YYYY").add(14, 'days');
    const enddate = moment(trialend).format("YYYY-MM-DD");
    let uid = data.team.id;
    const docRef = await addDoc(collection(db, 'organizations'), { profile: formData ? formData : null, trialEndDate: enddate, adminId: data.channel.slackUserId, activeStatus: "active", name: data.team.name, id: data.team.id, stripeId: "", subscriptionId: "", subscriptionStatus: "Inactive" });
    await addDoc(collection(db, "logs"), { updatedBy: "-", createdBy: data.channel.slackUserId, type: "organizaion", status: "success", teamId: data.team.id, message: "New organization " + data.team.name + " created", timestamp: moment().format("YYYY-MM-DD HH:mm:ss") });
    return docRef;
  } catch (error) {
    console.error("Error adding user: ", error);
    await addDoc(collection(db, "logs"), { updatedBy: "-", createdBy: data.channel.slackUserId, type: "organizaion", status: "error", teamId: data.team.id, message: error + " in creating organization <" + data.team.name + ">", timestamp: moment().format("YYYY-MM-DD HH:mm:ss") });

  }
};

export const getOrganization = async (id: string) => {

  const q = query(collection(db, "organizations"), where("id", "==", id));
  let org = null;
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    org = doc.data();
    // dataArr.push({
    //   ...doc.data(),
    //   id: doc.id
    // })
  });
  return org;

  // const docRef = doc(db, "organizations", id);
  // let org = await getDoc(docRef);
  // if (org?.exists()) {
  //   return org.data();
  // } else {
  //   // doc.data() will be undefined in this case
  //   console.log("No such document!");
  //   return null;
  // }

  //   return org;
};


export const orgAdminDataPresent = async (id: string) => {
  // const docRef = doc(db, "organizations", id);
  // let org = await getDoc(docRef);
  // if (org?.exists()) {
  //   console.log(org.data())
  //   if(org.data().profile){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // } else {
  //   // doc.data() will be undefined in this case
  //   console.log("No such document!");
  //   return null;
  // }


  const q = query(collection(db, "organizations"), where("id", "==", id));
  let res = false;
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach(async (doc) => {
    // doc.data() is never undefined for query doc snapshots
    // org = doc.data();
    if (doc.data().profile) {
      res = true;
    } else {
      res =  false;
    }
  });
  return res;
};

export const updateOrg = async (data: { name: string; email: string; occupation: string; gender: string | null; dob: string; }, id: unknown, auth: { channel?: any; team?: any; }) => {


  try {
    const q = query(collection(db, "organizations"), where("id", "==", id));
    let org = null;
    const querySnapshot = await getDocs(q);
  
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      org = doc.id;
  
    });
      const dbInstance = doc(db, "organizations", org);
      let res = await updateDoc(dbInstance, { profile: data });
      await addDoc(collection(db, "logs"), { updatedBy: auth.channel.slackUserId, createdBy: auth.channel.slackUserId, type: "user", status: "success", teamId: auth.team.id, message: "<" + auth.channel.slackUserId + `>' updated organization details`, timestamp: moment().format("YYYY-MM-DD HH:mm:ss") });
      return res;

  } catch (error) {
    console.error("Error updating document: ", error);
    await addDoc(collection(db, "logs"), { updatedBy: auth.channel.slackUserId, createdBy: auth.channel.slackUserId, type: "user", status: "error", teamId: auth.team.id, message: error + " in updating organization details <" + auth.channel.slackUserId + ">", timestamp: moment().format("YYYY-MM-DD HH:mm:ss") });
  }

};