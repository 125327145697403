const Stripe = require('stripe');
const key = process.env.REACT_APP_STRIPE_SECRET;
const stripe = Stripe(key);
const url = process.env.REACT_APP_SLACK_REDIRECT_URI?.slice(0, -4);

export const ListCustomers = async () => {
    try {
        const customers = await stripe.customers.list().then((response: any) => {
            return response
        })
            .catch((error: any) => {
                console.log(error);
            });
        if (customers.data) {
            return customers.data;
        }
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const SearchCustomer = async (name: string) => {
    try {
        const customers = await stripe.customers.search(
            { query: `name: " ${name}"` }
        ).then((response: any) => {
            return response
        })
            .catch((error: any) => {
                console.log(error);
            });
        if (customers.data) {
            if (customers.data.length) {
                return { data: customers.data[0] }
            } else {
                return { data: "No data" }
            }
        }
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const CreateCustomer = async (name: string, email: string) => {
    try {

        // let name = "Xoom Softwares"
        // let email = "nirmal@xoomsoftware.com"

        const customer = await stripe.customers.create({
            name: name,
            email: email,
        });
        return customer;

    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const ListPaymentMethods = async (customer: string) => {
    try {
        const setupIntents = await stripe.customers.listPaymentMethods(customer).then((response: any) => {
            return response;
        })
            .catch((error: any) => {
                console.log(error);
            });
        if (setupIntents.data) {
            return setupIntents.data;
        }
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const DeletePaymentMethod = async (paymentId: string) => {
    try {
        const setupIntent = await stripe.paymentMethods.detach(
            paymentId
        );
        return setupIntent;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const CreateSetupIntent = async (customer: string) => {
    try {
        const setupIntent = await stripe.setupIntents.create({
            payment_method_types: ['card'],
            customer: customer
        });
        return setupIntent;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const RetrieveSetupIntent = async (intent: any) => {
    try {
        const setupIntent = await stripe.setupIntents.retrieve(
            intent
        );
        return setupIntent;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const RetrievePaymentIntent = async (intent: any) => {
    try {
        const setupIntent = await stripe.paymentIntents.retrieve(
            intent
        );
        return setupIntent;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}


export const ListProducts = async () => {
    try {
        const customers = await stripe.products.list().then((response: any) => {
            return response
        })
            .catch((error: any) => {
                console.log(error);
            });
        if (customers.data) {
            return customers.data;
        }
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const ListPrice = async () => {
    try {
        const price = await stripe.prices.list().then((response: any) => {
            return response
        })
            .catch((error: any) => {
                console.log(error);
            });
        if (price.data) {
            return price.data;
        }
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}


// export const createSubscription = async (priceId, customerId) => {
//     // let customerId = "cus_QjjBqKoZzybnbN";
//     try {
//         const subscription = await stripe.subscriptions.create({
//             customer: customerId,
//             items: [{
//                 price: priceId,
//             }],
//             payment_behavior: 'default_incomplete',
//             payment_settings: { save_default_payment_method: 'on_subscription' },
//             expand: ['latest_invoice.payment_intent'],
//         })
//             .catch((error) => {
//                 console.log(error);
//             });
//         return subscription;
//     } catch (error) {
//         return {
//             message: "error",
//             error: error
//         }
//     }
// }

export const createCheckoutSession = async (priceId: any, customerId: any, email: any) => {
    // let customerId = "cus_QjjBqKoZzybnbN";
    try {
        const subscription = await stripe.checkout.sessions.create({
            // customer: customerId,
            customer_email: email,
            line_items: [
                {
                    price: priceId,
                    quantity: 3,
                },
            ],
            mode: 'subscription',
            success_url:  url + 'billing/success?session_id={CHECKOUT_SESSION_ID}',
            cancel_url:  url + 'billing/failed',
        })
            .catch((error: any) => {
                console.log(error);
            });
        return subscription;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const createSubscription = async (priceId: any, customerId: any, userCount: any) => {
    // let customerId = "cus_QjjBqKoZzybnbN";
    try {
        const subscription = await stripe.subscriptions.create({
            customer: customerId,
            items: [
                {
                    price: priceId,
                    quantity: 3,
                },
            ],
            payment_behavior: 'default_incomplete',
            payment_settings: { save_default_payment_method: 'on_subscription' },
            expand: ['latest_invoice.payment_intent'],
        })
            .catch((error: any) => {
                console.log(error);
            });
        return subscription;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const updateCustomerDefaultPayment = async (customerId: string, subId: any, payment: any) => {
    // let customerId = "cus_QjjBqKoZzybnbN";
    try {
        const customer = await stripe.customers.update(
            customerId,
            {
                invoice_settings: {
                    default_payment_method: payment,
                },
            }
        )
            .catch((error: any) => {
                console.log(error);
            });

        const subscription = await stripe.subscriptions.update(subId, {
            default_payment_method: payment,
        }).catch((error: any) => {
            console.log(error);
        });

        return subscription;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const ListSubscriptions = async (customer: any) => {
    try {
        const subscriptions = await stripe.subscriptions.list().then((response: any) => {
            return response
        })
            .catch((error: any) => {
                console.log(error);
            });

        if (subscriptions.data) {
            let filteredData = null
            subscriptions.data.map((sub: { customer: any; status: string; }) => {
                if (sub.customer == customer && sub.status == "active") {
                    filteredData = sub;
                    // filteredData.push(sub);
                }
            })
            return filteredData;
        }
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const changeSubscription = async (subId: any, items: any,) => {
    try {
        const subscription = await stripe.subscriptionItems.update(subId, {
            price: items,
        })
            .catch((error: any) => {
                console.log(error);
            });
        return subscription;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const updateSubscription = async (subId: any,items: any,) => {
    // let customerId = "cus_QjjBqKoZzybnbN";
    try {
        const subscription = await stripe.subscriptionItems.update(subId, {
            // customer: customerId,
            price: items,
            quantity: 4,
            // default_payment_method: paymentMethod ,
            // payment_behavior: 'default_incomplete',
            // payment_settings: { save_default_payment_method: 'on_subscription' },
            // expand: ['latest_invoice.payment_intent'],
        })
            .catch((error: any) => {
                console.log(error);
            });
        return subscription;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const cancelSubscription = async (subId: any) => {
    // let customerId = "cus_QjjBqKoZzybnbN";
    try {
        const subscription = await stripe.subscriptions.cancel(subId)
            .catch((error: any) => {
                console.log(error);
            });
        return subscription;
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

export const ListInvoices = async (customerId: any) => {
    try {
        const invoices = await stripe.invoices.list({
            customer: customerId
        }).then((response: any) => {
            return response
        })
            .catch((error: any) => {
                console.log(error);
            });
        if (invoices.data) {
            return invoices.data;
        }
    } catch (error) {
        return {
            message: "error",
            error: error
        }
    }
}

