import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './theme.css';
import App from './App';
import '/node_modules/primeflex/primeflex.css'
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
// import "/node_modules/primereact/resources/themes/lara-light-cyan/theme.css";

// ReactDOM.render(

//   <React.StrictMode>
//     <link href="https://fonts.googleapis.com/css?family=Bungee+Inline" rel="stylesheet"></link>
//     <PrimeReactProvider>
//     <App />
//     </PrimeReactProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );



const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
    <PrimeReactProvider>
    <link href="https://fonts.googleapis.com/css?family=Bungee+Inline" rel="stylesheet"></link>
      <App />
    </PrimeReactProvider>
  // </React.StrictMode>,

);

// store.subscribe(() => root.render(<App />));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
