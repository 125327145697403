
import React from "react";
// const cominsoon2 =  require("../../images/comingsoon2.jpg");
import cominsoon2 from "../../images/comingsoon2.jpg"
import { Card } from 'primereact/card';
export default function ComingSoon() {


    return (
        <Card style={{ height: '100vh' }}>
            <div className="flex justify-content-center" >
                <img alt="Card" src={cominsoon2} width={'30%'} style={{ zIndex: 15, }} />
            </div>
            <div className="flex justify-content-center" >
                <h1 style={{ fontSize: 75, }}>Coming Soon!!  </h1>


            </div>

            <div className="flex justify-content-center" >
                <h1 style={{ color: '#005CE8' }}>  We are currently working on this page. </h1>
            </div>


        </Card>

    );
}