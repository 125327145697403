import Topbar from "../../components/Topbar";
import MyProfile from './MyProfile';

export default function Profile() {
  return (
    <>
      <div>
        <div> <Topbar title={"Profile"} /></div>
      </div>
      <div className="card mx-5 my-5 ">
        {/* <TabView>
          <TabPanel header="My Profile"> */}
            <MyProfile />
          {/* </TabPanel>
          <TabPanel header="Users">
            <UsersList />
          </TabPanel>
        </TabView> */}
      </div>

    </>

  )
}
