import { useSearchParams, useNavigate, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { SignIn } from "../api/SignIn";
import { getUser, addUser } from "../api/Users";
import { getParticipants } from "../api/Participants";
import { getChannels } from "../api/Channels";
import { flowsPresentById } from "../api/Flows";
import { addOrganization, getOrganization } from "../api/Organizations";
import { ProgressSpinner } from 'primereact/progressspinner';
import { user } from "../types/types";

const colors = [
    "#D98880", "#C39BD3", "#7FB3D5", "#76D7C4", "#82E0AA", "#F7DC6F", "#F0B27A", "#898DE8", "#F18AD4", "#89E892"
]

function Auth() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get("code") && searchParams.get("code") !== undefined) {
            const result = getSlackAccess();
            // if (result) {
            //     navigate("/templates");
            // } else {
            //     navigate("/");
            // }
        }
    }, [searchParams, searchParams.get("code")]);



    const getSlackAccess = async () => {
        const responseFromAPI = async () => {
            const response = await SignIn(searchParams.get("code"));
            return response;
        };

        responseFromAPI().then((data) => {

            if (data?.message === "success") {
                const result = authObj(data);
                return result;
            } else {
                // navigate("/");
                // return <Navigate to="/" />;
                // return false;
                return null;
            }
        });
    };

    const participant = async (token) => {
        const responseFromAPI2 = async () => {
            const response = await getParticipants(token)

            return response;
        };

        responseFromAPI2().then((data) => {
            if (data?.message === "success") {
                let obj = {};
                let arrForSelect = [];
                for (let index = 0; index < data?.data?.members.length; index++) {
                    const element = data?.data?.members[index];
                    if (element.deleted == false && element.is_bot == false) {
                        if (
                            (element.deleted == false &&
                                element.is_bot == false &&
                                element.name != "slackbot") ||
                            (element.is_bot == true && element.name != "geekbot")
                        ) {
                            obj[data?.data?.members[index]?.id] = element;
                            Object.defineProperty(obj[data?.data?.members[index]?.id], 'color', {
                                value: colors[index % 10],
                                writable: false,
                            });
                            let objForSelect = {
                                label: element?.real_name,
                                value: element?.id,
                            };
                            arrForSelect[arrForSelect.length] = objForSelect;
                            // arrForSelect.push(objForSelect)
                        }
                    }
                }

                localStorage.setItem("participantList", JSON.stringify(obj));
                // setParticipantObj(obj);
                // return arrForSelect;

            } else {
                console.log("=== else ===", data);
            }

        });



    }

    const channels = async (token) => {

        const responseFromAPI3 = async () => {
            const response = await getChannels(token)

            return response;
        };

        responseFromAPI3().then((data) => {
            if (data?.message === "success") {
                let obj = {};
                let arrForSelect = [];

                for (let index = 0; index < data?.data?.channels.length; index++) {
                    const element = data?.data?.channels[index];
                    let objForSelect = {
                        label: element?.name,
                        value: element?.id,
                    };
                    obj[data?.data?.channels[index]?.id] = element;
                    arrForSelect[arrForSelect.length] = objForSelect;
                }
                // console.log("=== obj ===", obj);
                localStorage.setItem("channelList", JSON.stringify(obj));
                // localStorage.setItem("firstLoad", true);
            } else {
                // console.log("=== else ===", data);
            }
        });
    };

    const authObj = async (res) => {
        if (res?.data?.ok) {
            let team = {
                id: res?.data?.team?.id,
                name: res?.data?.team?.name,
            };

            let channel = {
                id: res?.data?.app_id,
                slackUserId: res?.data?.authed_user?.id,
            };

            let userData = {
                fname: "",
                lname: "",
                dob: "",
                email: "",
                occupation: "",
                gender: "",
                slackName: ""
            }

            let obj = {
                channel,
                team,
                token: res?.data?.authed_user?.access_token,
                userType: res?.data?.authed_user?.token_type,
                activeStatus: true,
                profile: userData,
            };

            if (typeof window !== "undefined") {
                localStorage.setItem("auth", JSON.stringify(obj));
                const now = new Date().getTime();
                // localStorage.setItem('setupTime', now);
            }

            await participant(JSON.stringify({ token: obj.token }));
            await channels(JSON.stringify({ token: obj.token }));
            let user = await getUser(team.id + channel.slackUserId) as user;
            let org = await getOrganization(team.id);
            if (!org) {
                await addOrganization(obj);
            }
            let userResponse;
            if (user.channel) {
                userResponse = user;
                obj.userType = user.userType;
                localStorage.setItem("auth", JSON.stringify(obj));
                // navigate('/templates');
                // return <Navigate to="/templates" />;
                // return true;
            } else {
                // const name = await participant(JSON.stringify({ token: obj.token }), obj.channel.slackUserId);


                userResponse = await addUser(obj).then((data) => {
                    // if (data) {
                    //     // navigate("/templates");
                    //     navigate("/");
                    // } else {
                    //     navigate("/");
                    // }
                })
                // navigate("/");
            }
            if (obj) {

                const flow = await flowsPresentById(obj?.team.id);
                if (flow == true) {
                    navigate("/workflows");
                } else {
                    navigate("/templates");
                }
                // window.location.reload();
            }
        }
    };


    return (
        <>
        </>
    );
}

export default Auth;