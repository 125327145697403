import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import Topbar from "../../components/Topbar";
import Details from "./Details"
import Timeline from "./TimelineData"
import Streaks from "./Streaks"
import Filters from "./Filters"
import { getFlow, getStreaks } from "../../api/Flows";
import { getTimeline, getTimelineCount, getNextTimeline } from "../../api/Timeline";
import { getTodayStatus } from "../../api/Statuses";
import { getBarChartData, getPieChartData } from "../../api/Insights";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from "react-router-dom";
import MediaQuery from 'react-responsive'
import { getUser } from "../../api/Users";
import { flow, timelineData } from "../../types/types";
const moment = require("moment");

const colors = [
  "#C87171", "#8ECEA0", "#7194C8", "#D8D472"
]
function Template() {
  const { id } = useParams();
  const navigate = useNavigate();
  const savedValue = JSON.parse(window.localStorage.getItem("auth"));
  const [loading, setLoading] = useState(true);
  const [nextLoading, setNextLoading] = useState(false);
  const [timelineLoading, setTimelineLoading] = useState(true);
  const [barLoading, setBarLoading] = useState(false);
  const [trueId, setTrueId] = useState(true);
  const [flow, setFlow] = useState<flow>();
  const [points, setPoints] = useState(1);
  const [count, setCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [barChartData, setBarChartData] = useState({});
  const [pieChartData, setPieChartData] = useState({});
  const [participantList, setParticipantList] = useState('all');
  const [participantObj, setParticipantObj] = useState([]);
  const [timelineList, setTimelineList] = useState([]);
  const [channels, setChannels] = useState([]);
  const [streaks, setStreaks] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [chartFilter, setChartFilter] = useState({ name: 'This week', code: 'this week' });
  const [selectionRange, setSelectionRange] = useState({
    startDate: moment().startOf('isoWeek').format("YYYY-MM-DD") + " " + "00:00",
    endDate: moment().endOf('isoWeek').format("YYYY-MM-DD") + " " + "23:59",
    key: 'selection',
  });

  useEffect(() => {

    getTemplates(savedValue);
    const part = JSON.parse(window.localStorage.getItem("participantList"));
    setParticipantObj(part);
    const chan = JSON.parse(window.localStorage.getItem("channelList"));
    setChannels(chan);
    setLoading(true);
    const dateRange = {
      startDate: moment().startOf('isoWeek').format("YYYY-MM-DD") + " " + "00:00",
      endDate: moment().endOf('isoWeek').format("YYYY-MM-DD") + " " + "23:59",
      key: 'selection',
    }
    getTimelineList(id, 10, dateRange, "all");
    getCount(id, dateRange);
    getTodayStatusList(id, dateRange);

  }, []);

  const getTemplates = async (savedValue) => {
    setLoading(true);
    await getFlow(id).then((data) => {
      console.log(data);
      if (data == null) {
        setTrueId(false);
      } else {
        setFlow(data as flow);
        if (data.schedule != 'Custom') {
          setPoints(Object?.keys(data?.days).length);
        }
      }


      //   if (data.schedule == 'Weekly') {
      //     setPoints(1)
      // } else if (data.schedule  == '2 weeks period') {
      //     setPoints(2)
      // } else if (data.schedule  == '3 weeks period') {
      //     setPoints(3)
      // } else if (data.schedule == '4 weeks period') {
      //     setPoints(4)
      // }else{
      //   // setPoints(5)
      // }
    });
    const user = await getUser(savedValue.team.id + savedValue.channel.slackUserId);
    console.log(user)
    setUserData(user);

  };

  const handleChartFilter = async (range) => {
    setBarLoading(true);
    setChartFilter(range);
    let obj = {
      startDate: "",
      endDate: "",
      key: 'selection',
    }
    if (range.code == "today") {
      handleChartData(id, range.code);

    }else{
      if (range.code == "this week") {
        obj.startDate = moment().startOf('isoWeek').format("YYYY-MM-DD") + " " + "00:00";
        obj.endDate = moment().endOf('isoWeek').format("YYYY-MM-DD") + " " + "00:00";
  
      }  else if (range.code == "this month") {
        obj.startDate = moment().startOf('month').format("YYYY-MM-DD") + " " + "00:00";
        obj.endDate = moment().endOf('month').format("YYYY-MM-DD") + " " + "23:59";
      } else if (range.code == "last 3 months") {
        obj.startDate = moment().subtract(3, 'months').startOf('month').format("YYYY-MM-DD") + " " + "00:00";
        obj.endDate = moment().startOf('month').format("YYYY-MM-DD") + " " + "23:59";
  
      }else if (range.code == "last 6 months") {
        obj.startDate = moment().subtract(6, 'months').startOf('month').format("YYYY-MM-DD") + " " + "00:00";
        obj.endDate = moment().startOf('month').format("YYYY-MM-DD") + " " + "23:59";
      }
  
      setSelectionRange({
        startDate: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + " " + "00:00",
        endDate: moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD") + " " + "23:59",
        key: 'selection',
      })
      handleChartData(id, obj);
    }
    
  }

  const handleChartData = async (id, selectionRange) => {
    let barchartData = await getBarChartData(id, selectionRange);
    let piechartData = await getPieChartData(id);
    setBarChartData(barchartData);
    setPieChartData(piechartData);
    setBarLoading(false);
  }

  const nextTimelineData = async (count) => {
    setNextLoading(true);
    let flows = await getNextTimeline(id, count, selectionRange, participantList);
    let flowArr = flows?.docs?.map((item) => {
      // if (item.data()?.flow.docId == params) {
      return { ...item.data(), id: item.id };
      // }
    });
    let newArr = flowArr && flowArr.filter((el) => {
      return el !== undefined;
    });
    // let authObj = JSON.parse(localStorage.getItem("auth"));
    // let final = newArr.filter((el) => {
    //   return el?.teamId == authObj?.team?.id;
    // });

    let final = []
    newArr && newArr.map((item : any) => {
      let a = {
        name: participantObj[item.userId]?.profile ? participantObj[item.userId]?.profile?.real_name : participantObj[item.userId]?.name[0],
        userId: item.userId,
        questions: item.flow.questions,
        date: moment(item.createdAt).format("YYYY-MM-DD"),
        time: moment(item.createdAt).format("HH:mm"),
        answers: item.answers,
        createdAt: item.createdAt,
        flow: item.flow,
      }
      final.push(a);
    });

    let finalArr = [];
    let timeData = [...timelineList];
    final.map((data) => {
      if (finalArr.length == 0) {
        let x = {
          date: moment(data.createdAt).format("YYYY-MM-DD"),
          data: [data]
        }
        finalArr.push(x)
      } else {
        const i = finalArr.findIndex(e => e.date == moment(data.createdAt).format("YYYY-MM-DD"));
        if (i > -1) {
          finalArr[i].data.push(data)
        } else {
          let x = {
            date: moment(data.createdAt).format("YYYY-MM-DD"),
            data: [data]
          }
          finalArr.push(x)
          timeData.push(x);
        }
      }
    })
    setTimelineList(timeData);
    setNextLoading(false);
  //   setTimelineList(timeData, () => {
  //     setNextLoading(false);
  // });
   

  }

  const handleTimelineData = async (count, range, participantList) => {
    if (range) {
      await getCount(id, range);
      setSelectionRange(range);
      getTimelineList(id, count, range, participantList);
      setParticipantList(participantList);
      setSelectedCount(count);
    } else {
      await getCount(id, selectionRange);
      getTimelineList(id, count, selectionRange, participantList);
      setParticipantList(participantList);
      setSelectedCount(count);
    }

  }

  const handleUserChanged = async (range, participantList) => {

    await getCount(id, range);
    setSelectionRange(range);
    getTimelineList(id, 10, range, participantList);
    setSelectedCount(count);
  }

  const getCount = async (params, range) => {
    let count = await getTimelineCount(params, range) as any;
    setCount(count?._data?.count.integerValue);
  };

  const getTimelineList = async (params, limit, range, participants) => {
    setTimelineLoading(true);
    let flows = await getTimeline(params, limit, range, participants);

    let streaks = await getStreaks(params);
    let streaksArr = streaks?.docs?.map((item) => {
      return { ...item.data(), id: item.id };

    });
    let sortedArr = [];
    streaksArr.map((streak : any) => {
      if (streak.count > 2) {
        sortedArr.push(streak);
      }
    })
    let topStreaks = Object.values(sortedArr).sort((a, b) => b.count - a.count).slice(0, 3);
    setStreaks(topStreaks);
    await handleChartData(params, selectionRange);
    let flowArr = flows?.docs?.map((item : any) => {
      // if (item.data()?.flow.docId == params) {
      return { ...item.data(), id: item.id };
      // }
    });
    let newArr = flowArr && flowArr.filter((el) => {
      return el !== undefined;
    });
    // let authObj = JSON.parse(localStorage.getItem("auth"));
    // let final = newArr.filter((el) => {
    //   return el?.teamId == authObj?.team?.id;
    // });

    let final = []
    newArr && newArr.map((item) => {
      let a = {
        name: participantObj[item.userId]?.profile ? participantObj[item.userId]?.profile?.real_name : participantObj[item.userId]?.name[0],
        userId: item.userId,
        questions: item.flow.questions,
        date: moment(item.createdAt).format("YYYY-MM-DD"),
        time: moment(item.createdAt).format("HH:mm"),
        answers: item.answers,
        createdAt: item.createdAt,
        flow: item.flow,
      }
      final.push(a);
    });

    let finalArr = [];
    final.map((data) => {
      if (finalArr.length == 0) {
        let x = {
          date: moment(data.createdAt).format("YYYY-MM-DD"),
          data: [data]
        }
        finalArr.push(x)
      } else {
        const i = finalArr.findIndex(e => e.date == moment(data.createdAt).format("YYYY-MM-DD"));
        if (i > -1) {
          finalArr[i].data.push(data)
        } else {
          let x = {
            date: moment(data.createdAt).format("YYYY-MM-DD"),
            data: [data]
          }
          finalArr.push(x)
        }
      }
    })
    setTimelineList(finalArr);
    // setFlows(flowArr);
    setTimelineLoading(false);
    setLoading(false);
  };

  const getTodayStatusList = async (params, selectionRange) => {
    let status = await getTodayStatus(params, selectionRange);
    let statusArr = status?.docs?.map((item : any) => {
      return { ...item.data(), id: item.id };
      // }
    });

    let newArr = statusArr.filter((el) => {
      return el !== undefined;
    });

    let unique_values = newArr && newArr.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.user.id === value.user.id && value.status == "out of office"
      ))
    )



    setStatusList(unique_values);

  };

  return (
    <div>
      <div>
        <Topbar title={flow?.title} subtitle2={"Workflows/"} />
        {loading ? (
          <div className="flex align-items-center justify-content-center flex-wrap" >
            <h1><ProgressSpinner /> </h1>
          </div>
        ) : trueId == true ? (
          <div className="mx-5 my-5">

            <Details id={id} colors={colors} channels={channels} savedValue={savedValue} flow={flow} participantObj={participantObj} pieChartData={pieChartData} statusList={statusList} points={points} userData={userData} />
            <MediaQuery minWidth={470}>
              <div className="grid">
                <div className="col-9 my-5 ">
                  <Timeline colors={colors} timelineList={timelineList} participantObj={participantObj} barChartData={barChartData} handleChartFilter={handleChartFilter}
                    chartFilter={chartFilter} timelineLoading={timelineLoading} nextTimelineData={nextTimelineData} barLoading={barLoading} nextLoading={nextLoading} />
                </div>
                <div className="col-3">
                  <div className="my-5">
                    <Filters participantObj={participantObj} flow={flow} handleTimelineData={handleTimelineData} handleUserChanged={handleUserChanged} />
                  </div>
                  <div className="my-5">
                    <Streaks streaks={streaks} participantObj={participantObj} />
                  </div>

                </div>
              </div>
            </MediaQuery>

            <MediaQuery maxWidth={470}>
            <div className="my-5">
                <Streaks streaks={streaks} participantObj={participantObj} />
              </div>
            <div className="my-5">
                    <Filters participantObj={participantObj} flow={flow} handleTimelineData={handleTimelineData} handleUserChanged={handleUserChanged} />
                  </div>
              <Timeline colors={colors} timelineList={timelineList} participantObj={participantObj} barChartData={barChartData} handleChartFilter={handleChartFilter}
                chartFilter={chartFilter} timelineLoading={timelineLoading}  nextTimelineData={nextTimelineData} barLoading={barLoading} nextLoading={nextLoading} />


            </MediaQuery>
          </div>
        ) : (
          <Card style={{ height: '100vh' }}>
            <div className="flex justify-content-center" >
              {/* <img alt="Card" src={image404} width={'30%'} style={{ zIndex: 15, }} /> */}
              <p style={{ fontSize: 160, fontWeight: 800, margin: '2vh', color: '#005CE8' }}>Oops! </p>
            </div>
            <div className="flex justify-content-center" >
              {/* <h1  style={{fontWeight:600, fontSize:40}}>404 - PAGE NOT FOUND</h1> */}


            </div>

            <div className="flex justify-content-center" >
              <h3 style={{ color: 'black' }}>  The id of the flow that you are looking for might be wrong, please check the id and try again</h3>
            </div>

            <div className=" my-6 flex justify-content-center" >
              <Button className="px-5" label="GO TO WORKFLOWS" aria-label="Edit" onClick={() => navigate("/workflows")} />
            </div>
          </Card>
        )}

      </div>
    </div>
  );
}

export default Template;