import {
    collection,
    getDocs,
  } from "firebase/firestore";
  import { db } from "../firebaseConfig";
  
  const dbInstance = collection(db, "plans");

export const getPlans = async () => {
    try {
      let flows = await getDocs(dbInstance).then((data) => {
        return data;
      });
  
      return flows;
    } catch (error) {
      console.error("Error retreving document: ", error);
    }
  };