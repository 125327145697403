import React, { useState, useRef } from "react";
import { Dropdown } from 'primereact/dropdown';
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
// import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chart } from 'primereact/chart';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';

const moment = require("moment");

const chartFilters = [
    { name: 'Today', code: 'today' },
    { name: 'This week', code: 'this week' },
    { name: 'This month', code: 'this month' },
    { name: 'Last 3 months', code: 'last 3 months' },
    { name: 'Last 6 months', code: 'last 6 months' },
];

function Timeline({
    colors,
    timelineList,
    participantObj,
    barChartData,
    handleChartFilter,
    chartFilter,
    timelineLoading,
    barLoading,
    nextTimelineData,
    nextLoading
}) {

    const [limit, setLimit] = useState(10);

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                },
                max: barChartData?.maxValue + 1
            },


        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: 'rgb(255, 99, 132)'
                }
            }
        }

    };

    const element = useRef(null);
    const scrollEvent = async (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            console.log("End");
            setLimit(limit + 10)
            nextTimelineData(limit);
            // let flows = await getTimeline(params, limit, range, participants);
        }
    }

    return (
        <Card>

            <Tabs>
                <TabList>
                    <Tab><h4>Timeline</h4></Tab>
                    <Tab><h4>Insights</h4></Tab>
                </TabList>

                <TabPanel>
                    <div id="scroll-box">
                        {timelineLoading ? (
                            <div className="flex justify-content-center" style={{ maxHeight: '78vh' }}>
                                <h3><ProgressSpinner /> </h3></div>
                        ) : (<div onScroll={scrollEvent} ref={element} style={{ maxHeight: '78vh', overflow: 'scroll' }}>
                            {timelineList.length == 0 ? (
                                <div className="flex justify-content-center" style={{ maxHeight: '78vh' }}>
                                    <h3>- No Data -</h3></div>

                            ) : (
                                timelineList && timelineList.map((an) => (
                                    <div >
                                        <div className="flex justify-content-center flex-wrap" >
                                            <div>
                                                <h4 style={{ color: "#959FA3", fontWeight: 400 }}>{"- " + moment(an.date).format("dddd MMM Do YYYY" + " -")}</h4></div>
                                        </div>
                                        {an.data.map((answer, index) => {
                                            return (
                                                <>

                                                    <div className="grid">
                                                        <div className="col-1 ">
                                                            <div >
                                                                <Avatar label={participantObj[answer.userId]?.profile ? participantObj[answer.userId]?.profile?.real_name[0] : participantObj[answer.userId]?.real_name[0]} style={{ backgroundColor: participantObj && participantObj[answer.userId].color, color: '#ffffff', }} shape="circle" size="large" />
                                                            </div>
                                                        </div>
                                                        <div className="col-11 ">
                                                            <h4 style={{ marginTop: 0 }}>{participantObj[answer.userId]?.profile ? participantObj[answer.userId]?.profile?.real_name + " - " + (moment(answer.createdAt).format('LT')) : participantObj[answer.userId]?.real_name
                                                                + " - " + (moment(answer.createdAt).format('LT'))}</h4>
                                                            {answer.questions.map((question, index) => (
                                                                <div className="m-2" style={{ borderLeft: '2px solid', borderColor: colors[index % 4] }}>
                                                                    <div className="m-2">
                                                                        <b>{question}</b>
                                                                        <p>{answer.answers[index]}</p></div>
                                                                </div>
                                                            ))}</div> </div>
                                                </>

                                            )
                                        })}
                                    </div>
                                ))
                            )}
                            {nextLoading ? (<div className="flex justify-content-center"><ProgressSpinner style={{ width: '50px', height: '50px' }} /></div>) : (<div />)}
                            {/* <div className="flex justify-content-end">
                                <Button onClick={() => moreSelected()} style={{ padding: 1 }} text severity="secondary" aria-label="Bookmark" >
                                    {/* <h6 style={{ margin: 1, fontWeight: 200, color: '#959FA3' }}>{count > selectedCount ? 'Show less' : 'Show all'}</h6>  
                                    <h4 style={{ margin: 1, fontWeight: 200, color: '#959FA3' }}>{selectedCount != 0 && count > selectedCount ? 'See More...' : ''}</h4>
                                </Button></div>*/}
                        </div>)}

                    </div>
                </TabPanel>
                <TabPanel>
                    <p className="m-0">
                        <div className="grid m-2">
                            <div className="col-9">
                                <h2 style={{ color: '#05004E' }}>Users who have participated in this period</h2>
                                <p style={{ marginBottom: 3 }}> <span style={{ width: 20, color: '#005CE8' }} className="pi pi-circle-fill"></span> Users who have participated in this task</p>
                            </div>

                            <div className="col-3 ">
                                <h2 >
                                    <Dropdown value={chartFilter} onChange={(e) => handleChartFilter(e.value)} options={chartFilters} optionLabel="name"
                                        placeholder="Select a Filter" className="w-full md:w-14rem" />
                                </h2>
                            </div>
                        </div>
                        {barLoading ? (
                            <div className="flex justify-content-center" style={{ maxHeight: '78vh' }}>
                                <h3><ProgressSpinner /> </h3></div>
                        ) : (
                            <Chart type="bar" data={barChartData} options={chartOptions} />
                        )}

                    </p>
                </TabPanel>
            </Tabs>
            {/* <TabView>
                <TabPanel header="Timeline">
                   

                </TabPanel>
                <TabPanel header="Insights">
                  
                </TabPanel>
            </TabView> */}


        </Card >
    );
}

export default Timeline;