
import React, { useRef, useState, useEffect } from "react";
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { Card } from "primereact/card";
import { loadStripe } from "@stripe/stripe-js";
import {  SearchCustomer, createSubscription } from '../../api/Stripe';
import { getPlans } from '../../api/Plans';
import { useMediaQuery } from 'react-responsive'
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './Checkout';

export default function BillingWizard() {
    const stepperRef = useRef(null);
    const [view, setView] = useState("package");
    const stripePromise = loadStripe("pk_test_WRr6k7JXHNlcbgVpTDKSHp7p");
    const [secretType, setSecretType] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [intentId, setIntentId] = useState("");
    const [plans, setPlans] = useState([]);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })

    useEffect(() => {
        setupProducts();
    }, []);

    const appearance = {
        theme: 'stripe' as const,
    };
    const options = {
        clientSecret,
        appearance,
    };


    const setupProducts = async () => {
        const plns = await getPlans();
        const planData = plns?.docs?.map((item) => {
            if (item.data() && item.data().status == "active") {
                return { ...item.data(), id: item.id };
            }
        })
        let filteredData = [];
        planData.map(async (item : any) => {
            if (item && item?.status == "active") {
                filteredData.push(item);
            }
        })
        setPlans(filteredData);

    }

    const activatePlan = async (index, data) => {
        const savedValue = JSON.parse(window.localStorage.getItem("auth"));
        let customerdata = await SearchCustomer(savedValue.team.name);
        console.log(customerdata)
        const subs = await createSubscription(data.priceId, customerdata.data.id , 3);
        setClientSecret(subs.latest_invoice.payment_intent.client_secret);
        setIntentId(subs.id);
        setClientSecret(subs.latest_invoice.payment_intent.client_secret);
        console.log(subs.latest_invoice.payment_intent.client_secret)
        setSecretType("payment");
        stepperRef.current.nextCallback()

    }

    return (
        <>

            <div className="card flex flex-wrap justify-content-center align-content-center  m-5">

                    <Card style={{width : isDesktopOrLaptop ? '60%' : '100%'}}>
                    <Stepper linear  ref={stepperRef}>
                        <StepperPanel header="Select Plan">

                            <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                <div className="text-900 font-bold text-6xl mb-4 text-center">Pricing Plans</div>
                                <div className="text-700 text-xl mb-6 text-center line-height-3">Please select a plan from below.</div>

                                <div className="grid">

                                    {plans && plans.map((info, index) => (
                                        <>
                                            <div className="col-12 lg:col-6">
                                                <div className="p-3 h-full">
                                                    <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                                                        <div className="text-900 font-medium text-xl mb-2">{info.name}</div>
                                                        <div className="text-600">Plan description</div>
                                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                                        <div className="flex align-items-center">
                                                            <span className="font-bold text-2xl text-900">{info.amountPerUser}</span>
                                                            <span className="ml-2 font-medium text-600">per user</span>
                                                        </div>
                                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                                        <ul className="list-none p-0 m-0 flex-grow-1">
                                                            {info.features.map((feat) => (
                                                                <li className="flex align-items-center mb-3">
                                                                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                                    <span className="text-900">{feat}</span>
                                                                </li>
                                                            ))}

                                                        </ul>
                                                        <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                                                        <Button style={{ marginBottom: 5 }} outlined label="Choose Plan" severity='info' color="#005CE8" aria-label="Edit" onClick={() => activatePlan(index, info)} />


                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    ))}

                                </div>
                            </div>

                            {/* <div className="flex pt-4 justify-content-end">
                                <Button label="Next" icon="pi pi-arrow-right" disabled={active ? false : true} iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                            </div> */}
                        </StepperPanel>
                        <StepperPanel header="Add Card">
                            <div className="flex flex-column h-17rem">
                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutForm clientSecret={clientSecret} intentId={intentId} secretType={secretType} setView={setView} cancelBtn={() => stepperRef.current.prevCallback()}/>
                                </Elements>
                            </div>
                            {/* <div className="flex pt-4 justify-content-between">
                                <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                                <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                            </div> */}
                        </StepperPanel>
                        <StepperPanel header="Finalize">
                            {/* <div className="flex flex-column h-12rem">
                                <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content III</div>
                            </div> */}
                            <div className="flex pt-4 justify-content-start">
                                <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                            </div>
                        </StepperPanel>
                    </Stepper>
                    </Card>
                </div>

        </>
    )
}
