import {
    collection,
    addDoc,
    getDocs,
    doc,
    getDoc,
    updateDoc,
    deleteDoc,
    query,
    onSnapshot,
    where
  } from "firebase/firestore";
  import { db } from "../firebaseConfig";
import { flow } from "../types/types";
  const moment = require("moment");
  // import { TasksContext } from '../components/common/tasksContext';
  
  const dbInstance = collection(db, "flows");
  
  export const addFlow = async (data : any) => {
    try {
      const docRef = await addDoc(collection(db, "flows"), {...data,updatedAt:""});

      await addDoc(collection(db, "logs"), {updatedBy: "-",  createdBy: data.createdBy, type: "flow", status: "success", teamId:data.teamId,  message: data.title + " flow " + " created by <" + data.createdBy + ">", timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
      return docRef.id;
    } catch (error) {
      console.error("Error adding document: ", error);
      await addDoc(collection(db, "logs"), {updatedBy: "-",  createdBy: data.createdBy, type: "flow", status: "error", teamId:data.teamId,  message: error + " in creating" +  data.title , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
   
    }
  };
  
  export const getFlows = async () => {
    try {
      let flows = await getDocs(dbInstance).then((data) => {
        return data;
      });
  
      return flows;
    } catch (error) {
      console.error("Error retreving document: ", error);
    }
  };

  export const flowsPresentById = async (teamId: any) => {
    const q =  query(collection(db, "flows"),where("teamId", "==", String(teamId)));
    let dataArr: { id: string; }[] = [];
    const querySnapshot = await getDocs(q);
   
    let flows = querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      dataArr.push({
        ...doc.data(),
        id: doc.id
      })
    });
    if (dataArr && dataArr.length > 0){
      return true ;
    }else{
      return false ;
    }
    
    
  };

  export const validateFlowsbyOrg = async (teamId: any) => {
    const q =  query(collection(db, "flows"),where("teamId", "==", String(teamId)));
    let count = 0;
    const querySnapshot = await getDocs(q);
   
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      count = count + 1;
    });

      return count ;
    
    
  };
  
  
  export const getFlowsById = async (teamId: any, status: { name: any; } | null) => {
    if(status){
      const q =  query(collection(db, "flows"),where("teamId", "==", String(teamId)),where("activeStatus", "==", String(status.name)));
      let dataArr: { id: string; }[] = [];
      const querySnapshot = await getDocs(q);
      let flows = querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        dataArr.push({
          ...doc.data(),
          id: doc.id
        })
      });
      
      return dataArr ;
    }else{
      const q =  query(collection(db, "flows"),where("teamId", "==", String(teamId)));
      let dataArr: { id: string; }[] = [];
      const querySnapshot = await getDocs(q);
      let flows = querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        dataArr.push({
          ...doc.data(),
          id: doc.id
        })
      });
      
      return dataArr ;
    }


  };
  
  export function getFlowSnapshot(cb: (arg0: { id: string; }[]) => void, filters = {}) {
    if (typeof cb !== "function") {
            console.log("Error: The callback parameter is not a function");
            return;
    }
  
    let q = query(collection(db, "flows"));
    // q = applyQueryFilters(q, filters);
  
    const unsubscribe = onSnapshot(q, querySnapshot => {
            const results = querySnapshot.docs.map(doc => {
                    return {
                            id: doc.id,
                            ...doc.data(),
                            // Only plain objects can be passed to Client Components from Server Components
                            // createdAt: doc.data().createdAt.toDate(),
                    };
            });  
            // return results;
  
            cb(results);
    });
  
    return unsubscribe;
  }
  
  
  
  
  export const getFlow = async (id: string | undefined) => {
    const docRef = doc(db, "flows", id);
    let flow = await getDoc(docRef);
    // if (flow?.exists()) {
    //   return flow.data()!;
    // } 
    if (flow) {
      return flow.data();
      // In this block, data is now typed as just DocumentData,
      // undefined is no longer an option.
  }
    else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      return null;
    }
  
    // return flow;
  };
  
  export const getStreaks = async (id: string) => {
    try {
      let flows = await getDocs(collection(db, "flows", id, "streaks")).then((data) => {
        return data;
      });
  
      return flows;
    } catch (error) {
      console.error("Error retreving document: ", error);
    }
  };
  
  export const updateFlow = async (data: any, id: string | undefined) => {
    const dbInstance = doc(db, "flows", id);
    try{
      let res = await updateDoc(dbInstance, data);
      await addDoc(collection(db, "logs"), {updatedBy: data.updatedBy,  createdBy: data.createdBy, type: "flow", status: "success", teamId:data.teamId,  message: data.title + " flow updated by <" + data.updatedBy + ">", timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
      return res;
    }catch (error){
      console.error("Error adding document: ", error);
      await addDoc(collection(db, "logs"), {updatedBy: data.updatedBy,  createdBy: data.createdBy, type: "flow", status: "error", teamId:data.teamId,  message: error + " in updating" +  data.title , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
   
    }

  };
  
  export const deleteFlow = async (id: string | undefined, title: string,updatedBy: string, teamId: string ) => {
    try{
      let res = await deleteDoc(doc(db, "flows", id));
      await addDoc(collection(db, "logs"), {updatedBy: updatedBy,  createdBy: updatedBy, type: "flow", status: "success", teamId:teamId,  message: title + " flow deleted by <" + updatedBy + ">", timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
      return res;
    }catch (error){
      console.error("Error adding document: ", error);
      await addDoc(collection(db, "logs"), {updatedBy: updatedBy,  createdBy: "-", type: "flow", status: "error", teamId:teamId,  message: error + " in deleting" +  title , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
   
    }
  };
  