import {
    collection,
    query,
    where,
    getDocs,
    doc,
    getDoc,
    orderBy,
    startAfter,
    getCountFromServer,
    limit
  } from "firebase/firestore";
  import { db } from "../firebaseConfig";
  
  const moment = require("moment");

    export const getTimeline = async (params: any, count: number,range: { startDate: any; endDate: any; },participantlist: any) => {
    try {
      let startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
      let endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");
      let first = null;
      if(participantlist == "all"){
        // let partArr = [];
        // participantlist.map((paticipants)=>{
        //   partArr.push(paticipants.id);
        // })
        // first = query(collection(db, "answers", String(params), "userAnswers"),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'));
        first = query(collection(db, "answers", String(params), "userAnswers"),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'), limit(count));

      }else{
        // first = query(collection(db, "answers", String(params), "userAnswers"),where("userId", "in", participantlist),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'));
          first = query(collection(db, "answers", String(params), "userAnswers"),where("userId", "in", participantlist),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'), limit(count));
       
      }
      
      const documentSnapshots = await getDocs(first);

      // // Get the last visible document
      const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

      // // Construct a new query starting at this document,
      // // get the next 25 entries.
      // const next = query(collection(db, "answers", String(params), "userAnswers"),startAfter(lastVisible), limit(10));

      let flows = await getDocs(first).then((data) => {
        return data;
      });
  
      return flows;
    } catch (error) {
      console.error("Error retreving document: ", error);
    }
  };

  export const getNextTimeline = async (params: string | undefined, count: any,range: { startDate: any; endDate: any; key?: string; },participantlist: any) => {
    try {
      let startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
      let endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");
      let start = count;
      let first = null;
      if(participantlist == "all"){
        // first = query(collection(db, "answers", String(params), "userAnswers"),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'));
        first = query(collection(db, "answers", String(params), "userAnswers"),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'), limit(start));

      }else{
        // first = query(collection(db, "answers", String(params), "userAnswers"),where("userId", "in", participantlist),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'));
        first = query(collection(db, "answers", String(params), "userAnswers"),where("userId", "in", participantlist),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'), limit(start));
       
      }
      
      const documentSnapshots = await getDocs(first);

      // // Get the last visible document
      const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

      // // Construct a new query starting at this document,
      // // get the next 25 entries.
      const next = query(collection(db, "answers", String(params), "userAnswers"),where("userId", "in", participantlist),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'),startAfter(lastVisible), limit(10));
       
      let flows = await getDocs(next).then((data) => {
        return data;
      });
  
      return flows;
    } catch (error) {
      console.error("Error retreving document: ", error);
    }
  };

  export const getTimelineCount = async (params: any, range: { startDate: any; endDate: any; }, participantlist?: any[] | undefined) => {
    let startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
    let endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");

    let first = null;
    if(participantlist && participantlist.length > 0){
      let partArr: any = [];
      participantlist.map((paticipants)=>{
        partArr.push(paticipants.id);
      })
        first = query(collection(db, "answers", String(params), "userAnswers"),where("userId", "in", partArr),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'));

    }else{
        first = query(collection(db, "answers", String(params), "userAnswers"),where("createdAt", ">=", startDate),where("createdAt", "<", endDate),orderBy('createdAt', 'desc'));
      
    }

    return getCountFromServer(
      first
    );

  };
  
  export const getFlow = async (id: string) => {
    const docRef = doc(db, "flows", id);
    let flow = await getDoc(docRef);
    if (flow?.exists()) {
      return flow.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  
    return flow;
  };

